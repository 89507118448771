import React, { Component } from 'react'
import { Router } from '@reach/router'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { companyRequest } from '../../actions'

import NotFoundPage from '../../pages/404'
import SimpleTemplateWrapper from './t-simple-template-wrapper'
import Aloader from '../01-atoms/a-loader'
import APaper from '../01-atoms/a-paper'
import OCompanyDetail from '../03-organisms/o-company-detail'

/** TDetail used to fetch and display company details */
class TDetail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoaded: false,
      queriedSlug: null,
      result: false,
    }
  }

  componentDidMount() {
    const queriedSlug = this.props.company

    this.props.companyRequest({
      companyId: queriedSlug,
      query: 'single',
    })
  }

  render() {
    const { placeholderImgDetail } = this.props
    // check if there are api results
    if (this.props.restApiResponse.successGet) {
      const { category, company, tag } = this.props.restApiResponse.apiResults

      return (
        // TODO: Use headline of SimpleTemplateWrapper?
        <SimpleTemplateWrapper showHeadlineBar>
          <APaper>
            <OCompanyDetail
              companyData={company}
              tags={tag}
              categories={category}
              placeholderImgDetail={placeholderImgDetail}
            />
          </APaper>
        </SimpleTemplateWrapper>
      )
    } else if (
      this.props.restApiResponse.apiResults === false ||
      this.props.restApiResponse.currentlySending
    ) {
      return (
        <SimpleTemplateWrapper>
          <APaper>
            <Aloader />
          </APaper>
        </SimpleTemplateWrapper>
      )
    }

    // if JSON request failed and fallback for other edge cases
    return (
      <Router>
        <NotFoundPage />
      </Router>
    )
  }
}

TDetail.propTypes = {
  /** Matched Route */
  company: PropTypes.string,
  companyRequest: PropTypes.func.isRequired,
  restApiResponse: PropTypes.object,
  placeholderImgDetail: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    restApiResponse: {
      apiResults: state.apiResults,
      currentlySending: state.currentlySending,
      successGet:
        state.lastCall == 'companyGetSingle' &&
        typeof state.apiResults == 'object' &&
        !Array.isArray(state.apiResults),
    },
  }
}

const mapDispatchToProps = dispatch => {
  return {
    companyRequest: restApiResponse =>
      dispatch(companyRequest(restApiResponse)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TDetail)
