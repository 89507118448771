/**
 * MIconList
 *
 * @param {array} entries Entries should be an array of Objects.
 *    Each Object should have the following items:
 *    {
 *      'label': 'The link label',
 *      'target': 'The links href'
 *    }
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/styles/withStyles'
import Fab from '@material-ui/core/Fab'

import Website from '../../assets/svgs/website.svg'
import PressLink from '../../assets/svgs/press_link.svg'
import Blog from '../../assets/svgs/blog.svg'
import Github from '../../assets/svgs/github.svg'
import Twitter from '../../assets/svgs/twitter.svg'
import Linkedin from '../../assets/svgs/linkedin.svg'
import Xing from '../../assets/svgs/xing.svg'
import Facebook from '../../assets/svgs/facebook.svg'

import ALink from '../01-atoms/a-link.js'

const styles = theme => ({
  miniFab: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    boxShadow: 'none',
    width: theme.typography.pxToRem(30),
    height: theme.typography.pxToRem(30),
    minHeight: theme.typography.pxToRem(30),
    marginBottom: theme.typography.pxToRem(15),
    marginRight: theme.typography.pxToRem(10),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '& svg': {
      width: theme.typography.pxToRem(15),
      height: theme.typography.pxToRem(15),
      fill: theme.palette.secondary.contrastText,
    },
  },
  row: {},
})

class MIconList extends Component {
  constructor(props) {
    super(props)

    // eslint-disable-next-line react/display-name
    this.renderLink = React.forwardRef((itemProps, ref) => (
      <ALink to={this.props.to} {...itemProps} ref={ref} />
    ))
  }

  renderSwitch(slug) {
    switch (slug) {
      case 'website':
        return <Website />
      case 'press_link':
        return <PressLink />
      case 'blog':
        return <Blog />
      case 'github':
        return <Github />
      case 'twitter':
        return <Twitter />
      case 'linkedin':
        return <Linkedin />
      case 'xing':
        return <Xing />
      case 'facebook':
        return <Facebook />
      default:
        return ''
    }
  }

  render() {
    const { classes } = this.props

    const iconListEntries =
      typeof this.props.iconListEntries == 'object' &&
      this.props.iconListEntries.length
        ? this.props.iconListEntries.map((link, index) => {
            return typeof link.url == 'string' && link.url ? (
              <Fab
                key={index}
                component={this.renderLink}
                className={classes.miniFab}
                href={link.url}
              >
                {this.renderSwitch(link.slug)}
              </Fab>
            ) : (
              false
            )
          })
        : 'Please define your array of menu iconListEntries'

    return <div className={classes.row}>{iconListEntries}</div>
  }
}

MIconList.propTypes = {
  iconListEntries: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.object.isRequired,
  to: PropTypes.string,
}

export default withStyles(styles)(MIconList)
