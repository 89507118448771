import React from 'react'
import APaper from '../components/01-atoms/a-paper'

const NotFoundPage = () => (
  <APaper>
    <h1>Seite nicht gefunden</h1>
    <p>Die von Ihnen angeforderte Seite wurde nicht gefunden.</p>
  </APaper>
)

export default NotFoundPage
