/**
 * MTabs
 *
 */

import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import withStyles from '@material-ui/styles/withStyles'

import Tabscontainer from '../01-atoms/a-tabs-container'

const styles = theme => ({
  tabContainer: {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.customStyles.extraColors.lineGrey,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '30px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '50px',
    },
  },
  tab: {
    textTransform: 'uppercase',
  },
  tabLabel: {
    fontSize: '13px',
    [theme.breakpoints.up('md')]: {
      fontSize: '15px',
    },
  },
})

class MTabs extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: 0,
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event, value) {
    this.setState({ value })
  }

  render() {
    const { classes, tabLabels, children } = this.props
    const { value } = this.state

    const tabs = children.length
      ? children
          .filter(child => {
            return child != ''
          })
          .map((child, index) => {
            return (
              <Tab
                key={index}
                className={classes.tab}
                label={
                  <span className={classes.tabLabel}>{tabLabels[index]}</span>
                }
              />
            )
          })
      : false

    const tabscontainer = children.length
      ? children
          .filter(child => {
            return child != ''
          })
          .map((child, index) => {
            return (
              value === index && (
                <Tabscontainer key={index}>{child}</Tabscontainer>
              )
            )
          })
      : false

    return tabs.length ? (
      <Fragment>
        <div className={classes.tabContainer}>
          <Tabs
            value={value}
            onChange={this.handleChange}
            className={classes.tabs}
          >
            {tabs}
          </Tabs>
        </div>
        {tabscontainer}
      </Fragment>
    ) : (
      ''
    )
  }
}

MTabs.propTypes = {
  /** add classes for styling element */
  classes: PropTypes.object.isRequired,
  /** use children node to add content for tabs */
  children: PropTypes.node.isRequired,
  tabLabels: PropTypes.array,
}

export default withStyles(styles)(MTabs)
