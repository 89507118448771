import React, { Component } from 'react'
import { Router } from '@reach/router'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import TDetail from '../../components/04-templates/t-detail'

/** PageCompany used for company detail page */
class PageCompany extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { data } = this.props
    // create new Route with template component
    return (
      <Router>
        <TDetail
          path="/firma/:company"
          placeholderImgDetail={data.placeholderImgDetail}
        />
      </Router>
    )
  }
}

PageCompany.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PageCompany
// eslint-disable-next-line
export const query = graphql`
  query GatsbyImagePageCompanyQuery {
    placeholderImgDetail: file(relativePath: { eq: "images/placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 300, toFormat: PNG) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
