/**
 * ATabscontainer
 *
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

class ATabscontainer extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { children } = this.props
    return <Typography component="div">{children}</Typography>
  }
}

ATabscontainer.propTypes = {
  children: PropTypes.node,
}

export default ATabscontainer
