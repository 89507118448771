import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

import ALink from '../01-atoms/a-link.js'
import IconArrowRight from './../../assets/svgs/icon-arrow-right.svg'

const styles = theme => ({
  container: {
    marginBottom: theme.typography.pxToRem(30),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.typography.pxToRem(50),
    },
  },
  imageContainer: {
    [theme.breakpoints.only('xs')]: {
      paddingBottom: '0px !important',
    },
  },
  image: {
    width: '100%',
  },
  headline: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: '500',
    marginBottom: theme.typography.pxToRem(9),
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  paragraph: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.customStyles.extraColors.darkGrey,
    marginBottom: theme.typography.pxToRem(20),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(25),
      marginBottom: theme.typography.pxToRem(30),
    },
  },
  link: {
    textDecoration: 'none',
    '& span': {
      fontSize: theme.typography.pxToRem(14),
      color: theme.customStyles.extraColors.darkGrey,
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(16),
      },
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    '& svg': {
      width: theme.typography.pxToRem(14),
      height: theme.typography.pxToRem(11),
      fill: theme.palette.primary.main,
      marginRight: theme.typography.pxToRem(5),
    },
  },
})

class AReference extends Component {
  render() {
    const { classes, image, headline, children, link } = this.props
    return (
      <Grid container spacing={5} className={classes.container}>
        <Grid
          item
          xs={12}
          sm={5}
          classes={{
            item: classes.imageContainer,
          }}
        >
          {image ? (
            <img
              src={image.src}
              srcet={image.srcset}
              alt={image.title}
              className={classes.image}
            />
          ) : (
            false
          )}
        </Grid>
        <Grid item xs={12} sm={7}>
          <Typography variant="h3" className={classes.headline}>
            {headline}
          </Typography>
          <div
            className={classes.paragraph}
            dangerouslySetInnerHTML={{
              __html: children,
            }}
          />
          {link ? (
            <ALink className={classes.link} to={link} noButton>
              <IconArrowRight />
              <span>Zur Website</span>
            </ALink>
          ) : (
            false
          )}
        </Grid>
      </Grid>
    )
  }
}

AReference.propTypes = {
  /** Target of link */
  to: PropTypes.string,
  /** The source of the image. Will be delegated to the <img> Markup. */
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** use children node if your logo is no simple image */
  children: PropTypes.node,
  /** the classes to add styling to elements */
  classes: PropTypes.object,
  /** The headline for the reference text */
  headline: PropTypes.string,
  /** The url the button should link to */
  link: PropTypes.string,
}

export default withStyles(styles)(AReference)
