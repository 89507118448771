import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/styles/withStyles'

import AResponsiveEmbed from '../01-atoms/a-responsive-embed'

const styles = theme => ({
  headline: {
    fontSize: '20px',
    fontWeight: '500',
    marginBottom: '20px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '25px',
      lineHeight: '33px',
    },
  },
})

class MTextVideo extends Component {
  render() {
    if (typeof this.props.src != 'string' || !this.props.src.length) return ''

    const { classes, ...props } = this.props

    return (
      <Fragment>
        <Typography variant="h2" className={classes.headline}>
          Video
        </Typography>
        <AResponsiveEmbed {...props} />
      </Fragment>
    )
  }
}

MTextVideo.propTypes = {
  src: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MTextVideo)
