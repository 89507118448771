import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Chip from '@material-ui/core/Chip'
import { Html5Entities } from 'html-entities'
const htmlEntities = new Html5Entities()

/** AChip Component */
class AChip extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { label, to, className } = this.props

    // @url https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-link#for-internal-links-only
    // Tailor the following test to your environment.
    // This example assumes that any internal link (intended for Gatsby)
    // will start with exactly one slash, and that anything else is external.
    const internal = /^\/(?!\/)/.test(to)
    // Use gatsby-link for internal links, and <a> for others
    if (internal) {
      return (
        <Link to={to}>
          <Chip
            className={className}
            label={htmlEntities.decode(label)}
            component="div"
          />
        </Link>
      )
    }

    return (
      <a href={to}>
        <Chip
          className={className}
          label={htmlEntities.decode(label)}
          component="div"
        />
      </a>
    )
  }
}

AChip.propTypes = {
  /** Target of this Link. External links will be opened in a new window. Internal links (starting with /) will use the gatsby-link component. */
  to: PropTypes.string,
  label: PropTypes.string,
  /** if true, we don't use the material ui button */
  className: PropTypes.string,
}

export default AChip
