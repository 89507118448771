import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import CardMedia from '@material-ui/core/CardMedia'
import { Html5Entities } from 'html-entities'
const htmlEntities = new Html5Entities()

import Img from 'gatsby-image'

import AChip from '../01-atoms/a-chip'
import AGridList from '../01-atoms/a-grid-list'
// import AIconBusiness from '../01-atoms/a-icon-business'
// import AIconPremium from '../01-atoms/a-icon-premium'
import MTextVideo from '../02-molecules/m-text-video'
import MReferenceList from '../02-molecules/m-reference-list'
import MSocialList from '../02-molecules/m-social-list'
import MTabs from '../02-molecules/m-tabs'

import { globalNaming } from '../../../site-config'

import { FormattedMessage } from 'react-intl'
import _ from 'lodash'

const styles = theme => ({
  companyHeader: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  logo: {
    backgroundColor: 'white',
    marginRight: theme.typography.pxToRem(30),
    border: '1px solid rgba(0,0,0,0.1)',
    width: 148,
    height: '100%',
    minWidth: 148,
    minHeight: 148,
    borderRight: '1px solid rgba(0,0,0,0.1)',
    backgroundSize: 'contain',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.typography.pxToRem(-100),
      width: 200,
      height: '100%',
      minWidth: 200,
      minHeight: 200,
    },
    [theme.breakpoints.only('xs')]: {
      display: 'block',
      margin: '-80px auto 30px',
    },
  },
  companyMainInfos: {
    marginBottom: theme.typography.pxToRem(20),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.typography.pxToRem(40),
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto',
    marginBottom: theme.typography.pxToRem(15),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    color: theme.customStyles.extraColors.lightGrey,
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.typography.pxToRem(-15),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.typography.pxToRem(-25),
    },
  },
  fabIcon: {
    marginRight: theme.typography.pxToRem(7),
  },
  companyName: {
    color: theme.palette.common.black,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(30),
    lineHeight: theme.typography.pxToRem(30),
    marginBottom: theme.typography.pxToRem(10),
    wordBreak: 'break-word',
    hyphens: 'auto',
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(45),
      lineHeight: theme.typography.pxToRem(45),
      marginBottom: theme.typography.pxToRem(15),
    },
  },
  category: {
    color: theme.customStyles.extraColors.lightGrey,
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(18),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  tags: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(19),
    fontWeight: 500,
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    height: theme.typography.pxToRem(25),
  },
  contentHeadline: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 500,
    marginBottom: theme.typography.pxToRem(20),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(25),
      lineHeight: theme.typography.pxToRem(33),
    },
  },
  portfolio: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(16),
    color: theme.customStyles.extraColors.darkGrey,
    fontWeight: 300,
    marginBottom: theme.typography.pxToRem(30),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(25),
      lineHeight: theme.typography.pxToRem(33),
      marginBottom: theme.typography.pxToRem(60),
    },
    '& p': {
      color: theme.customStyles.extraColors.darkGrey,
    },
  },
  content: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.customStyles.extraColors.darkGrey,
    marginBottom: theme.typography.pxToRem(30),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(28),
      marginBottom: theme.typography.pxToRem(70),
    },
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  sidebarHeadline: {
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(19),
    fontWeight: 500,
    marginBottom: theme.typography.pxToRem(5),
    display: 'block',
  },
  companyInfo: {
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(20),
    display: 'block',
  },
  phoneAndWebData: {
    fontSize: theme.typography.pxToRem(16),
    paddingTop: theme.spacing(2),
    marginBottom: theme.typography.pxToRem(20),
    display: 'block',
  },
  noUnderline: {
    ...theme.customStyles.noUnderline,
  },
})

/** OCompanyDetail used to display company details */
class OCompanyDetail extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let iconListEntries = ''
    const {
      classes,
      companyData,
      tags,
      categories,
      placeholderImgDetail,
    } = this.props

    const company_info = companyData.company_info

    // phone and fax are not in the company_info object at the moment, so we initialise them here so we can 'length-check' them down below without blowing up the logic there
    company_info.phone = _.has(company_info, 'phone') ? company_info.phone : ''
    company_info.fax = _.has(company_info, 'fax') ? company_info.fax : ''
    company_info.street = _.has(company_info, 'street')
      ? company_info.street
      : ''

    const showPremiumFields =
      companyData.company_label &&
      (companyData.company_label.value == 0 ||
        companyData.company_label.value == 1)

    if (companyData.company_links.length) {
      iconListEntries = companyData.company_links
    }

    const companyTagsMarkUp =
      typeof companyData.company_tags == 'object' &&
      companyData.company_tags.length
        ? companyData.company_tags.map((tagId, index) => {
            return (
              <AChip
                className={classes.tags}
                key={index}
                label={
                  typeof tags.mapped[tagId] == 'object'
                    ? tags.mapped[tagId].name
                    : ''
                }
              />
            )
          })
        : false

    const category =
      typeof companyData.company_categories == 'number'
        ? htmlEntities.decode(
            categories.mapped[companyData.company_categories].name
          )
        : ''

    const portfolio =
      company_info.portfolio && showPremiumFields ? (
        <Fragment>
          <Typography
            variant="h2"
            className={classes.contentHeadline}
            gutterBottom
          >
            <FormattedMessage id="portfolio" />
          </Typography>
          <div
            className={classes.portfolio}
            dangerouslySetInnerHTML={{
              __html: company_info.portfolio,
            }}
          />
        </Fragment>
      ) : (
        ''
      )

    const companyMeta = company_info.about_us
      ? company_info.about_us.replace(/]+>/gi, '')
      : ''

    const companyMetaTitel =
      globalNaming.entryType + 'verzeichnis | ' + company_info.company

    const about = company_info.about_us ? (
      <Fragment>
        <Typography
          variant="h2"
          className={classes.contentHeadline}
          gutterBottom
        >
          <FormattedMessage id="aboutUs" />
        </Typography>
        <div
          className={classes.content}
          dangerouslySetInnerHTML={{
            __html: company_info.about_us,
          }}
        />
      </Fragment>
    ) : (
      ''
    )

    const video =
      companyData.company_info.video && showPremiumFields ? (
        <MTextVideo src={companyData.company_info.video} allowFullScreen />
      ) : (
        ''
      )

    const gallery =
      companyData.company_info.gallery && showPremiumFields ? (
        <AGridList tileData={companyData.company_info.gallery} />
      ) : (
        ''
      )

    const overview =
      about || gallery || video ? (
        <div>
          {about}
          {gallery}
          {video}
        </div>
      ) : (
        ''
      )

    const references =
      typeof companyData.company_info.references == 'object' &&
      showPremiumFields ? (
        <MReferenceList referenceData={companyData.company_info.references} />
      ) : (
        ''
      )

    let tabLabels = []
    if (overview) {
      tabLabels.push('Übersicht')
    }
    if (references) {
      tabLabels.push('Referenzen')
    }

    if (
      companyData.company_label &&
      typeof companyData.company_label.label == 'string' &&
      typeof companyData.company_label.value == 'string'
    ) {
      var label
      switch (companyData.company_label.value) {
        case '0':
          label = (
            <div className={classes.label}>
              <div className={classes.fabIcon}>{/* <AIconPremium /> */}</div>
              {companyData.company_label.label} Member
            </div>
          )
          break
        case '1':
          label = (
            <div className={classes.label}>
              <div className={classes.fabIcon}>{/* <AIconBusiness /> */}</div>
              {companyData.company_label.label} Member
            </div>
          )
          break
        default:
          label = ''
          break
      }
    }

    const companyInformation =
      (typeof company_info.company_size === 'object' &&
        company_info.company_size.label) ||
      (typeof company_info.founding_year == 'string' &&
        company_info.founding_year.length) ? (
        <React.Fragment>
          <Typography
            className={classes.sidebarHeadline}
            variant="subtitle1"
            component="span"
          >
            Unternehmensdaten
          </Typography>
          <Divider className={classes.divider} />
          <Typography variant="caption" className={classes.companyInfo}>
            {typeof company_info.company_size === 'object' &&
            company_info.company_size.label
              ? `Mitarbeiterzahl ${company_info.company_size.label}`
              : ''}
            {typeof company_info.company_size === 'object' &&
            company_info.company_size.label ? (
              <br />
            ) : (
              ''
            )}
            {typeof company_info.founding_year == 'string'
              ? `gegründet ${company_info.founding_year}`
              : ''}
          </Typography>
        </React.Fragment>
      ) : (
        ''
      )

    const companyForm =
      typeof company_info.form_headline === 'string' &&
      typeof company_info.form_url === 'string' ? (
        <React.Fragment>
          <Typography
            className={classes.sidebarHeadline}
            variant="subtitle1"
            component="span"
          >
            {company_info.form_headline}
          </Typography>
          <Divider className={classes.divider} />
          <iframe
            src={company_info.form_url}
            width="100%"
            height={company_info.form_height}
            frameBorder="0"
          />
        </React.Fragment>
      ) : (
        ''
      )

    const logo =
      typeof companyData.company_info.logo == 'object' &&
      companyData.company_info.logo.src &&
      showPremiumFields ? (
        <CardMedia
          className={classes.logo}
          image={companyData.company_info.logo.src}
        />
      ) : (
        <Img
          className={classes.logo}
          fluid={placeholderImgDetail.childImageSharp.fluid}
        />
      )

    return (
      <Grid container spacing={5}>
        <Helmet
          title={companyMetaTitel}
          meta={[
            {
              name: 'title',
              content: companyMetaTitel,
            },
            {
              name: 'description',
              content: companyMeta,
            },
          ]}
        />
        <Grid item xs={12} md={8}>
          <div className={classes.companyHead}>
            <Grid
              container
              justify="flex-start"
              alignItems="flex-start"
              direction="row"
              spacing={8}
            >
              <Grid item xs={12} className={classes.companyHeader}>
                {logo}
                <div className={classes.companyMainInfos}>
                  {label}
                  <Typography variant="h2" className={classes.companyName}>
                    {company_info.company}
                  </Typography>
                  {category ? (
                    <Typography
                      className={classes.category}
                      variant="subtitle1"
                      component="p"
                    >
                      {category}
                    </Typography>
                  ) : (
                    ''
                  )}
                  {companyTagsMarkUp ? <div>{companyTagsMarkUp}</div> : ''}
                </div>
              </Grid>
              <Grid item xs={12}>
                {portfolio}
              </Grid>
            </Grid>
          </div>
          <MTabs tabLabels={tabLabels}>
            {overview}
            {references}
          </MTabs>
        </Grid>
        {/* sidebar start */}
        <Grid item xs={12} md={4}>
          {iconListEntries.length ? (
            <MSocialList iconListEntries={iconListEntries} />
          ) : (
            ''
          )}
          <Typography
            className={classes.sidebarHeadline}
            variant="subtitle1"
            component="span"
          >
            Kontakt
          </Typography>
          <Divider className={classes.divider} />
          <Typography component="p" paragraph className={classes.companyInfo}>
            {typeof company_info.street == 'string' &&
            company_info.street.length
              ? company_info.street
              : ''}
            {typeof company_info.city == 'string' &&
            company_info.city.length ? (
              <br />
            ) : (
              ''
            )}
            {typeof company_info.city == 'string'
              ? company_info.plz + ' ' + company_info.city
              : ''}
            {typeof company_info.city == 'string' &&
            company_info.city.length ? (
              <br />
            ) : (
              ''
            )}
            {company_info.country}
            {typeof company_info.country == 'string' &&
            company_info.country.length ? (
              <br />
            ) : (
              ''
            )}
            <Typography variant="caption" className={classes.phoneAndWebData}>
              <a
                href={'mailto:' + company_info.email}
                className={classes.noUnderline}
                target="_top"
              >
                {company_info.email}
              </a>
              {company_info.email.length ? <br /> : ''}
              {company_info.phone.length ? `t: ${company_info.phone}` : ''}
              {company_info.phone.length ? <br /> : ''}
              {company_info.fax.length ? `f: ${company_info.fax}` : ''}
            </Typography>
          </Typography>
          {companyInformation}
          {companyForm}
        </Grid>
      </Grid>
    )
  }
}

OCompanyDetail.propTypes = {
  /** The company object to display */
  companyData: PropTypes.object,
  classes: PropTypes.object.isRequired,
  tags: PropTypes.object,
  categories: PropTypes.object,
  placeholderImgDetail: PropTypes.object,
}

export default withStyles(styles)(OCompanyDetail)
