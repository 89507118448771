import React, { Component } from 'react'
import PropTypes from 'prop-types'

import AReference from '../01-atoms/a-reference'

class MReferenceList extends Component {
  render() {
    const { referenceData } = this.props
    return (
      <div>
        {referenceData.map(reference => (
          <AReference
            key={reference.headline}
            image={reference.image}
            headline={reference.headline}
            link={reference.link}
          >
            {reference.text}
          </AReference>
        ))}
      </div>
    )
  }
}

MReferenceList.propTypes = {
  /** an object of references of the exhibitor */
  referenceData: PropTypes.array,
}

export default MReferenceList
